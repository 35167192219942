import React, { useEffect, useState } from 'react'
import { AppProps, NextWebVitalsMetric } from 'next/app'
import Router from 'next/router'
import * as Sentry from '@sentry/node'
import { Page } from 'components'
import * as gtag from 'utils/gtag'
import { NODE_ENV, SENTRY_DSN } from 'constants/env'

import 'focus-visible'
import 'assets/styles/fonts.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

Router.events.on('routeChangeComplete', url => gtag.pageview(url))

if (SENTRY_DSN) {
  Sentry.init({
    enabled: NODE_ENV === 'production',
    dsn: SENTRY_DSN,
  })
}

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   if (NODE_ENV === 'development') {
//     console.log('metric', metric)
//   }
// }

interface Props extends AppProps {
  err: any
}

const CustomApp = ({ Component, pageProps, err }: Props) => {
  const [isLoading, setLoading] = useState(false)

  let previousPathname: any = null

  const handleOnLoading = (url: string) => {
    const pathname = url.split('?')[0]

    if (previousPathname && previousPathname !== pathname) {
      setLoading(true)
    }

    previousPathname = pathname
  }

  const handleOffLoading = () => {
    setLoading(false)
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', handleOnLoading)
    Router.events.on('routeChangeComplete', handleOffLoading)
    Router.events.on('routeChangeError', handleOffLoading)

    return () => {
      Router.events.off('routeChangeStart', handleOnLoading)
      Router.events.off('routeChangeComplete', handleOffLoading)
      Router.events.off('routeChangeStart', handleOffLoading)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page isLoading={isLoading}>
      <Component {...pageProps} err={err} />
    </Page>
  )
}

export default CustomApp
